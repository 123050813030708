import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Alert, Row, Table } from 'react-bootstrap';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesCollection: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Collection Items</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_coll.jpg"
            alt="Liberation"
          />
        </div>
        <div className="page-details">
          <h1>Collection Items</h1>
          <h2>Guide for the Collection Items system in NIKKE.</h2>
          <p>
            Last updated: <strong>24/06/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="What are Collection Items?" />
        <p>
          Also known as Dolls, or Collectibles. Collection Items are the newest
          power system in Nikke and you equip these items to your Nikkes. These
          are specific types of Commander's Dolls (basically Dolls that look
          like the commander), the names of which are distinct depending on what
          weapon type can equip them. When equipped, that Nikke will gain direct
          stats (HP - ATK - Def), multitude of buffs (Def up, Damage reduction,
          Cover's HP up) and a slight buff to their weapons. For example, Sniper
          Rifles will gain some bonus Charge Damage.
        </p>
        <SectionHeader title="How do Collection Items benefit me?" />
        <p>
          Effects of Collection items are increase based on the Phase of the
          doll. There are 15 phases in total, and 2 Rarities:{' '}
          <strong className="rarity-R">R</strong> and{' '}
          <strong className="rarity-SR">SR</strong>, with SR Collection Item
          having a stronger effect than R version.
        </p>
        <p>
          There are total 6 types of Collection items, two each (SR and R
          version){' '}
          <strong>for the 6 different types of Weapons in Nikke</strong>. A
          Nikke can only equip doll with corresponding weapon type, for example,
          Laplace, a Rocket Launcher can only be equipped with Rocket Launcher
          doll and not Assault Rifle doll.
        </p>
        <p>
          All dolls with same Rarity will have same stats and normal buffs. All
          dolls with same Phase will have same stats and normal buffs (stats
          include Phase 0 ~ Phase 15, and SR rarity version beside).
        </p>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Base Stats</th>
              <th>
                <strong className="rarity-R">R doll</strong>
              </th>
              <th>
                <strong className="rarity-SR">SR doll</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>HP</strong>
              </td>
              <td>19400 ~ 147250</td>
              <td>94000 ~ 301800</td>
            </tr>
            <tr>
              <td>
                <strong>DEF</strong>
              </td>
              <td>128 ~ 1002</td>
              <td>638 ~ 2058</td>
            </tr>
            <tr>
              <td>
                <strong>ATK</strong>
              </td>
              <td>638 ~ 4736</td>
              <td>3029 ~ 9688</td>
            </tr>
            <tr>
              <td>
                <strong>DEF ▲ buff</strong>
              </td>
              <td>25% ~ 32%</td>
              <td>30% ~ 37%</td>
            </tr>
          </tbody>
        </Table>
        <p>
          The only difference between them is Weapon buff, which are as follows
          (stats include Phase 0 ~ Phase 15).
        </p>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Weapon Type</th>
              <th>Buff</th>
              <th>
                <strong className="rarity-R">R doll</strong>
              </th>
              <th>
                <strong className="rarity-SR">SR doll</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>SG</strong>
              </td>
              <td>Increase weapon modifier</td>
              <td>1.57% ~ 6.3%</td>
              <td>4.74% ~ 9.47%</td>
            </tr>
            <tr>
              <td>
                <strong>RL</strong>
              </td>
              <td>Increase weapon modifier</td>
              <td>1.58% ~ 6.31%</td>
              <td>4.73% ~ 9.46%</td>
            </tr>
            <tr>
              <td>
                <strong>SR</strong>
              </td>
              <td>Increase weapon modifier</td>
              <td>1.58% ~ 6.31%</td>
              <td>4.73% ~ 9.46%</td>
            </tr>
            <tr>
              <td>
                <strong>SMG</strong>
              </td>
              <td>Increase weapon modifier</td>
              <td>1.57% ~ 6.3%</td>
              <td>4.74% ~ 9.47%</td>
            </tr>
            <tr>
              <td>
                <strong>AR</strong>
              </td>
              <td>Increase core damage</td>
              <td>5.67% ~ 12.49%</td>
              <td>10.22% ~ 17.04%</td>
            </tr>
            <tr>
              <td>
                <strong>MG</strong>
              </td>
              <td>Increase Max Ammo</td>
              <td>1.56% ~ 6.32%</td>
              <td>10.22% ~ 17.04%</td>
            </tr>
          </tbody>
        </Table>
        <p>Specially, SR Collections (Dolls) will have additional buffs:</p>
        <p>
          And on top of all these buffs above, Collection Items (Doll) will also
          increase the Nikke's COMBAT POWER value. For example, if a lv 395
          Nikke, equipped a R rarity doll may increase her CP by around 4000 CP!
        </p>
        <Alert variant="primary">
          <p>
            You want to fill your Campaign/Tower/PVP Nikkes with as many as
            Collections (dolls) as possible for CP Padding at least.
          </p>
        </Alert>
        <SectionHeader title="How to get Collection Item?" />
        <p>
          There are currently <strong>2 ways to Obtain Collection Item</strong>:
        </p>
        <ul>
          <li>Daily Dispatch</li>
          <li>Monthly Solo Raid.</li>
        </ul>
        <h5>Dispatch</h5>
        <p>
          Everyday, you can go to Outpost → Bulletin Board, where you can
          'Dispatch' your Nikkes to gain some rewards. Depend on your Tactics
          Academy's level, you will be able to access special Dispatch missions,
          which give Collection Items related items.
        </p>
        <StaticImage
          src="../../../images/nikke/collection/image8.webp"
          alt="Guide"
        />
        <br />
        <StaticImage
          src="../../../images/nikke/collection/image9.webp"
          alt="Guide"
        />
        <p>
          <strong>
            We suggest researching those levels as soon as possible, although
            they cost a HUGE amount of Credit.
          </strong>
        </p>
        <p>
          You can have{' '}
          <strong>maximum 4 (+1) Special Dispatch missions per day</strong>,
          with 3 types of rewards:
        </p>
        <ul>
          <li>
            Maintenance Kit - which are essentially materials used to upgrade a
            doll's phase/level. We will explain further in section below.
          </li>
          <li>Collection Mileage Box.</li>
          <li>
            This is the +1 mission. A special Dispatch (on top of the other 4)
            will give you 3 selected Favorite Maintenance Kits.
          </li>
        </ul>
        <p>
          Collection Mileage Boxes can be thought of as very similar to
          Manufacturer Arms. After collecting 200 of them, we can use the box to
          obtain 1 random Doll among 6 types of weapons (equal chance of each of
          them dropping). Well,{' '}
          <strong>
            80% chance is that the doll will be R version, with only 20% chance
            to be SR version.
          </strong>
        </p>
        <p>
          PS.{' '}
          <strong>
            You can reroll Dispatch missions in hope for better reward
          </strong>
          . We will have a dedicated guide for that strategy soon.
        </p>
        <h5>Solo Raid</h5>
        <StaticImage
          src="../../../images/nikke/collection/image10.webp"
          alt="Guide"
        />
        <p>
          Every month, Nikke will has an event named Solo Raid. When you clear
          all levels for the first time, you will receive in total:
        </p>
        <ul>
          <li>6 random R dolls (random weapon type).</li>
          <li>2 random SR dolls.</li>
          <li>
            1 SR doll Selector (yes you can choose whichever weapon doll you
            want).
          </li>
          <li>
            30 Random Favorite Maintenance Items (Explained later on Favorite
            Item Section).
          </li>
        </ul>
        <p>
          Clearing Solo Raid stages also gives you Maintenance Kits. If you do
          all 3 tries of Solo Raid everyday (excluding any challenge mode
          attempts), in total you will get 12× Maintenance Kit Box (which only
          give R and SR Kits), and 17× Maintenance Kit Box II (which also have a
          chance of dropping SSR variant of M.Kits).
        </p>
        <SectionHeader title="How to maintain / level up Collection Items?" />
        <p>
          Now assuming we have some dolls.{' '}
          <strong>How can we increase your Nikke's combat power?</strong>
        </p>
        <StaticImage
          src="../../../images/nikke/collection/image.webp"
          alt="Guide"
        />
        <p>Click here to start equipping your Collection on Nikke.</p>
        <StaticImage
          src="../../../images/nikke/collection/image2.webp"
          alt="Guide"
        />
        <p>Turns out you will still need to click the + symbol, again.</p>
        <StaticImage
          src="../../../images/nikke/collection/image3.webp"
          alt="Guide"
        />
        <p>
          Now you can choose to equip a R or SR version of Collection on your
          Nikke. Note that once you equip a doll to a Nikke, you won't be able
          to remove that doll anymore.
        </p>
        <Alert variant="primary">
          <p>
            You can REPLACE the doll from R version to SR version, but you{' '}
            <strong>will lose the R doll!</strong>
          </p>
        </Alert>
        <StaticImage
          src="../../../images/nikke/collection/image4.webp"
          alt="Guide"
        />
        <p>
          Once you equip your doll, you can check stats and all bonuses to your
          Nikke. Notice you can "CHANGE" and "MAINTAIN" here.
        </p>
        <ul>
          <li>
            Change: Only available if your doll is R version. Changing to SR
            version will carry all exp gained from R version.
          </li>
          <li>
            Maintain: Spending Maintenance Kit to increase your Collection's
            exp.
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/collection/image5.webp"
          alt="Guide"
        />
        <p>
          A doll has 15 phases, with exp required to level up each phase
          differently. Normally, you will spend Maintenance Kits to grind those
          exp slowly, moving from each phase to higher. But different kit
          rarities have different chances to move your doll immediately to Phase
          5, Phase 10, or Phase 15.
        </p>
        <p>
          Keep in mind, you can go from Phase 3 → Phase 5, but not Phase 3 →
          Phase 10. For example, in the pic above, there's 17.6% chance when you
          maintain this doll using Blue version, it will GO STRAIGHT TO PHASE 5
          instead of filling the exp bar of phase 1.
        </p>
        <p>
          There is actually a strategy to use Maintenance kits depend on each
          phase and rarity, stay tune for our guide!
        </p>
        <SectionHeader title="What are Favorite Items?" />
        <p>
          <strong>These are also known as Treasures</strong>. Now let's say, you
          levelled up your SR rarity doll to Phase 15. It MUST BE an SR rarity
          version doll, and it happened to be equipped on a specific Nikke,
          which for now you only have 4 choices for:
        </p>
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="laplace" enablePopover />
          <NikkeCharacter mode="icon" slug="frima" enablePopover />
          <NikkeCharacter mode="icon" slug="exia" enablePopover />
          <NikkeCharacter mode="icon" slug="diesel" enablePopover />
        </div>
        <p>
          <strong className="red">Important Note</strong>: You need Bond lvl 30
          to unlock favorite items for these units. This means you need Limit
          Break 2 (2 dupes/stars) on these units to unlock their treasures. If a
          unit does not have LB2, there is no point in upgrading these units.
        </p>
        <StaticImage
          src="../../../images/nikke/collection/image6.webp"
          alt="Guide"
        />
        <p>
          Then congratulations, you have unlocked the Favorite Item for that
          Nikke! These are a stronger version of Collection Item. A Favorite
          Item has 3 Phases.
        </p>
        <ul>
          <li>
            Phase 1: Unlock once you increase SR rarity Collection to Phase 15,
            and do some small missions that vary between characters.
          </li>
          <li>
            Phase 2: You must use exactly 50 character-specified Favorite Item
            Maintenance Kits to upgrade.
          </li>
          <li>
            Phase 3: Same as Phase 2 but the cost ramps up to 110 Favourite Item
            Maintenance Kits.
          </li>
        </ul>
        <StaticImage
          src="../../../images/nikke/collection/image7.webp"
          alt="Guide"
        />
        <p>Each character has different Favorite Item Maintenance Kits. </p>
        <ul>
          <li>Diesel's is a Train Track Set</li>
          <li>Exia's are AA Batteries</li>
          <li>Laplace's is a Super Ultrafine Fiber Cleaner</li>
          <li>Frima's is a UV Vacumn Cleaner</li>
        </ul>
        <SectionHeader title="What do Favorite Items do?" />
        <p>
          Favorite Items have the exact same stats as SR rarity Collection Phase
          15, but they also modify the Skills of that Nikke with each phase.{' '}
        </p>
        <StaticImage
          src="../../../images/nikke/collection/image11.webp"
          alt="Guide"
        />
        <p>
          Since each Nikke is changed differently with this Favorite system, you
          can go to Nikkepedia → Favorite Item → Click each Favorite Item to see
          how it changes the Nikke. We will release a review of 4 Nikkes with
          Favorite Items soon, don't miss them!
        </p>
        <SectionHeader title="Other guides in the series" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Collection Items - Upgrade Optimization"
            link="/nikke/guides/collection-dolls"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_dolls.jpg"
                alt="Collection Items - Upgrade Optimization"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Dispatch"
            link="/nikke/guides/collection-dispatch"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_dispatch.jpg"
                alt="Collection Items - Dispatch"
              />
            }
          />
          <CategoryCard
            title="Collection Items - Priority"
            link="/nikke/guides/collection-priority"
            image={
              <StaticImage
                src="../../../images/nikke/categories/category_priority.jpg"
                alt="Collection Items - Priority"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesCollection;

export const Head: React.FC = () => (
  <Seo
    title="Collection Items | NIKKE | Prydwen Institute"
    description="Guide for the Collection Items system in NIKKE."
  />
);
